import { queryFirst, queryAll, renderFragment, toFormData, remove } from '../domUtil';
import { dialogTemplate } from '../templates';
import { getSelectedPid } from './utils/productTileData';
import { SELECTOR_PRODUCT_TILE } from './productTileConstants';
import { addToCartAlert } from '../templates';
import { getAddToCartAnalyticsData } from '../product/helper';

const $body = $('body');

/**
 * Check is cart page
 *
 * @param {HTMLElement} container - product tile element
 * @returns {boolean} flowIPAddresses
 */
function isCartPage() {
    return !!queryFirst('#my-tote');
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    if (!response.error) {
        return;
    }

    if (response.displayModal) {
        let addToCartWarningDialog = queryFirst('#add-to-cart-warning-dialog');

        if (!addToCartWarningDialog) {
            const isOnCartPage = window.location.pathname === '/cart/';
            const fragment = renderFragment(
                dialogTemplate({
                    buttons: isOnCartPage ? [{ text: 'OK', primary: true }] : [{ text: 'Close' }, { text: 'Review Tote', primary: true, link: response.redirectLink }],
                    modalContentHeading: response.messageHeading,
                    modalContentBody: isOnCartPage ? response.messageBodyOnCart : response.messageBodyOffCart,
                    id: 'add-to-cart-warning-dialog'
                })
            );

            document.body.appendChild(fragment);
            addToCartWarningDialog = queryFirst('#add-to-cart-warning-dialog');
        }

        $(addToCartWarningDialog).modal('show');
    } else {
        const messageType = response.error ? 'alert-danger' : 'alert-success';
        // show add to cart toast
        remove(queryAll('.add-to-cart-messages'));

        let addToCartMessages = queryFirst('.add-to-cart-messages');
        if (!addToCartMessages) {
            document.body.appendChild(renderFragment('<div class="add-to-cart-messages"></div>'));
            addToCartMessages = queryFirst('.add-to-cart-messages');
        }

        addToCartMessages.appendChild(renderFragment(addToCartAlert(messageType, response.message)));

        setTimeout(() => {
            remove(queryAll('.add-to-basket-alert'));
        }, 5000);
    }
}

/**
 * Get Add to Cart url
 *
 * @param {HTMLElement} container - product tile element
 * @returns {string} flowIPAddresses
 */
function getAddToCartUrl(container) {
    return queryFirst('.add-to-cart-url', container).value;
}

const handleTilesAddToCart = () => {
    $body.on('click', '.js-tile-add-to-cart', async e => {
        e.preventDefault();
        const el = e.currentTarget;
        const container = el.closest(SELECTOR_PRODUCT_TILE);

        const pid = getSelectedPid(container);

        const analyticsData = getAddToCartAnalyticsData();

        const form = {
            pid,
            ...analyticsData
        };

        const addToCartUrl = getAddToCartUrl();
        if (pid && addToCartUrl) {
            try {
                const res = await fetch(addToCartUrl, {
                    method: 'POST',
                    body: toFormData(form)
                });
                if (!res.ok) throw new Error('Add to cart response was not OK');
                const resData = await res.json();
                handlePostCartAdd(resData);

                if (!resData.error && isCartPage()) {
                    $.spinner().start();
                    window.location.reload();
                } else {
                    $.spinner().stop();
                }

                $body.trigger('product:afterAddToCart', resData);
            } catch (err) {
                $.spinner().stop();
                console.error('addToCart error:', err.message);
            }
        }
    });
};

export default handleTilesAddToCart;
