import {
    CLASS_PRODUCT_TILE_INIT_EVENT_ATTACHED,
    SELECTOR_TILE_SWATCH_SELECTED,
    SELECTOR_SIZE_BTN,
    SELECTOR_SIZE_BTN_SELECTED,
    SELECTOR_PRODUCT_TILE_INIT_EVENT_NOT_ATTACHED,
    SELECTOR_NOTIFY_ME_BTN,
    SELECTOR_AVAILABILITY_MSG,
    SELECTOR_AVAILABILITY_MSG_TEXT,
    SELECTOR_EARLY_ACCESS_CTA_SIGN_IN,
    SELECTOR_EARLY_ACCESS_CTA_SIGN_UP,
    SELECTOR_ADD_TO_CART_BTN,
    SELECTOR_SOLDOUT_MSG,
    SELECTOR_TILE_PRICE_WRAPPER,
    SELECTOR_PRODUCT_TILE_CONTAINER
} from './productTileConstants';
import { queryFirst, queryAll, hasClass, addClass, removeClass, toggleClass } from '../domUtil';
import { getCustomerData, getProductInventoryData } from '../utils/ajaxData';
import { IN_STOCK_STATUS as IN_STOCK, NOT_AVAILABLE_STATUS as NOT_AVAILABLE, PREORDER_STATUS as PREORDER, HIDDEN_CLASS } from '../constants';
import { getProductInfo } from './utils/productTileData';
import { isHighGridDensity } from './utils/gridDensity';
const { initCarouselElement } = require('../components/swiperCarousel').default;

let observer;

/**
 *
 * @param {Object} productTileEl - product container html element
 * @param {Object} [productInventory] Product inventory object
 * @param {string} [action] action (init,update)
 */
async function updateCTAs(productTileEl, productInventory, action) {
    const selectedColorElement = queryFirst(SELECTOR_TILE_SWATCH_SELECTED, productTileEl);
    const selectedSizeElement = queryFirst(SELECTOR_SIZE_BTN_SELECTED, productTileEl);
    const selectedColorValue = selectedColorElement?.dataset?.attrValue;
    const selectedSizeValue = selectedSizeElement?.dataset?.attrValue;

    const addToCartButton = queryFirst(SELECTOR_ADD_TO_CART_BTN, productTileEl);
    const notifyMeButton = queryFirst(SELECTOR_NOTIFY_ME_BTN, productTileEl);
    const soldoutMsgEl = queryFirst(SELECTOR_SOLDOUT_MSG, productTileEl);

    const availabilityMsg = queryFirst(SELECTOR_AVAILABILITY_MSG, productTileEl);
    const clubLillySignIn = queryFirst(SELECTOR_EARLY_ACCESS_CTA_SIGN_IN, productTileEl);
    // join
    const clubLillySignUp = queryFirst(SELECTOR_EARLY_ACCESS_CTA_SIGN_UP, productTileEl);
    if (clubLillySignIn) {
        addClass(clubLillySignIn, HIDDEN_CLASS);
    }
    if (clubLillySignUp) {
        addClass(clubLillySignUp, HIDDEN_CLASS);
    }

    if (addToCartButton) {
        addClass(addToCartButton, HIDDEN_CLASS);
        addToCartButton.disabled = true;
    }
    addClass(notifyMeButton, HIDDEN_CLASS);
    addClass(availabilityMsg, HIDDEN_CLASS);

    const productInfoVariants = getProductInfo(productTileEl);
    const product = productInfoVariants ? productInfoVariants[selectedColorValue] : null;

    let customerData;
    const isEarlyAccessProduct = product?.earlyAccess?.isEarlyAccessProduct;

    if (isEarlyAccessProduct) {
        customerData = await getCustomerData();

        if (customerData.isAuthenticated) {
            if (customerData.isLoyaltyMember) {
                addToCartButton.disabled = !selectedSizeValue;
                removeClass(addToCartButton, HIDDEN_CLASS);
            } else {
                removeClass(clubLillySignUp, HIDDEN_CLASS);
            }
        } else {
            removeClass(clubLillySignIn, HIDDEN_CLASS);
        }
    }

    if (!addToCartButton || !product) {
        // if not add to cart, other also should be N/A
        return;
    }

    const { masterId } = productTileEl.dataset;
    if (!selectedSizeValue) {
        const productSizesValues = Object.values(product.sizes);

        if (!isEarlyAccessProduct) {
            let atLeastOneVariantAvailable = false;
            let atLeastOneVariantHasNotifyMe = productSizesValues.some(size => {
                return size.isNotifyMeEnabled;
            });

            if (action === 'init' && productSizesValues.length !== 1) {
                const sizeElements = queryAll(SELECTOR_SIZE_BTN, productTileEl);
                atLeastOneVariantAvailable = sizeElements.some(sizeElement => {
                    const { isNotAvailable } = sizeElement.dataset;
                    return isNotAvailable === 'false';
                });
            } else {
                productInventory = productInventory || await getProductInventoryData(masterId);

                atLeastOneVariantAvailable = productSizesValues.some(size => {
                    if (!productInventory || !(size.ID in productInventory.variants)) {
                        return false;
                    }
                    const { availabilityStatus } = productInventory.variants[size.ID];

                    return availabilityStatus === IN_STOCK;
                });
            }

            toggleClass(addToCartButton, HIDDEN_CLASS, !atLeastOneVariantAvailable);
            toggleClass(notifyMeButton, HIDDEN_CLASS, atLeastOneVariantAvailable || !atLeastOneVariantHasNotifyMe);
            toggleClass(soldoutMsgEl, HIDDEN_CLASS, atLeastOneVariantAvailable || atLeastOneVariantHasNotifyMe);
        }
        return;
    }

    const { ID: pid, isNotifyMeEnabled } = product.sizes[selectedSizeValue];

    productInventory = productInventory || (await getProductInventoryData(masterId));

    if (!productInventory?.variants?.[pid]) {
        return;
    }

    const { message: availabilityMessage, availabilityStatus } = productInventory.variants[pid];

    const isInStockAvailability = availabilityStatus === IN_STOCK;
    const isNotAvailableAvailability = availabilityStatus === NOT_AVAILABLE;
    const isPreorderAvailability = availabilityStatus === PREORDER;

    if (!isEarlyAccessProduct) {
        addToCartButton.disabled = !isInStockAvailability && !isPreorderAvailability;
        if (!isNotAvailableAvailability) {
            removeClass(addToCartButton, HIDDEN_CLASS);
        }
        notifyMeButton.disabled = !isNotAvailableAvailability;
        toggleClass(notifyMeButton, HIDDEN_CLASS, !isNotAvailableAvailability);
        toggleClass(notifyMeButton, HIDDEN_CLASS, !isNotAvailableAvailability || !isNotifyMeEnabled);
        toggleClass(soldoutMsgEl, HIDDEN_CLASS, !isNotAvailableAvailability || isNotifyMeEnabled);
        toggleClass(availabilityMsg, HIDDEN_CLASS, !isPreorderAvailability);

        if (isPreorderAvailability) {
            const availabilityMsgText = queryFirst(SELECTOR_AVAILABILITY_MSG_TEXT, productTileEl);

            availabilityMsgText.innerHTML = availabilityMessage;
        }
    }
}

/**
 * Attach tile specific events
 *
 * @param {HTMLElement} productTileEl  product tile element
 */
function attachTileEvents() {
    document.body.addEventListener('product:tile:cta:update', async e => {
        e.preventDefault();
        const productTileEl = e.target;

        if (!productTileEl) {
            return;
        }

        const { productInventory } = e.detail;
        await updateCTAs(productTileEl, productInventory, 'update');
    });
}

/**
 * Initialize product tile
 *
 * @param {HTMLElement} productTileEl  product tile element
 */
async function initVisibleElement(productTileEl) {
    const { isInitialized } = productTileEl.dataset;
    if (isInitialized) {
        return;
    }

    // for cases when event triggered before listners added would be used data attr
    productTileEl.dispatchEvent(
        new CustomEvent('product:tile:initialize', {
            bubbles: true,
            detail: {}
        })
    );

    // init swiper carousel
    const carouselEl = queryFirst('.js-tile-carousel', productTileEl);
    if (carouselEl) {
        initCarouselElement(carouselEl);
    }

    productTileEl.dataset.isInitialized = true;

    // remove extra step for tab navigation on high density:
    if (isHighGridDensity()) {
        const imageContainer = queryFirst('.image-container', productTileEl);

        if (imageContainer) {
            imageContainer.setAttribute('tabindex', '-1');
        }
    }

    await updateCTAs(productTileEl, null, 'init');
    addClass(productTileEl.closest(SELECTOR_PRODUCT_TILE_CONTAINER), 'animate-up');
}

/**
 * Callback for intersection observer - determines whether or not to initialize product tiles
 * @param {HTMLElement[]} productTiles list of observered product tiles
 */
function onIntersection(productTiles) {
    productTiles.forEach(async (media) => {
        // Is the media in viewport?
        if (media.intersectionRatio > 0) {
            const { target: productTileEl } = media;
            await initVisibleElement(productTileEl);
            observer.unobserve(productTileEl);
        }
    });
}

const initContainer = async container => {
    const allProductTilesElements = queryAll(SELECTOR_PRODUCT_TILE_INIT_EVENT_NOT_ATTACHED, container);

    const isContainEarlyAccessProducts = allProductTilesElements.some(element => element.dataset.isEarlyAccess === 'true');
    if (isContainEarlyAccessProducts) {
        // pre-cache customer data
        await getCustomerData();
    }

    if (!observer && window.IntersectionObserver) {
        observer = new IntersectionObserver(onIntersection, {
            // If the image gets within 200px of the top/bottom of the viewport, start the download.
            rootMargin: '200px 0px',
            threshold: 0.01
        });
    }

    if (!observer) return;

    allProductTilesElements.forEach(productTileEl => {
        observer.observe(productTileEl);
    });
};

/**
 * Attach event listeners only once
 */
function attachEventsListenersOnce() {
    attachTileEvents();
    const body = $(document.body); // use jQuery to get the 'body' element

    body.on('search:updateProducts', async function () {
        await initContainer(document);
    });

    body.on('search:showMore', async function () {
        setTimeout(async () => {
            await initContainer(document);
        }, 10);
    });

    body.on('search:densityChange', async function () {
        setTimeout(async () => {
            await initContainer(document);
        }, 10);
    });

    document.addEventListener('slot-loaded', async (e) => {
        const { target } = e;
        await initContainer(target);
    });
}

/**
 * Code to process some funcions only once even multiple includes
 */
const initOnce = (() => {
    let initialized = false;

    return () => {
        if (!initialized) {
            initialized = true;
            attachEventsListenersOnce();
        }
    };
})();

const productTileCustom = async () => {
    initOnce();

    await initContainer(document);
};

export default productTileCustom;
