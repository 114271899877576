
const { SELECTOR_PRODUCT_TILE, SELECTOR_PRODUCT_TILE_CONTAINER } = require('./productTileConstants.js');

const $body = $('body');

const handleTileEvents = () => {
    // Adding to history page URL on wich the current product tile is available
    // for case when user from 6 page (for example) scrolled up to 2, open PDP and then press Browser Back Button
    $body.on('click', SELECTOR_PRODUCT_TILE, e => {
        const el = e.currentTarget;
        const container = el.closest(SELECTOR_PRODUCT_TILE_CONTAINER);
        const { pageNumber = null } = container?.dataset || {};

        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('start') && urlParams.has('sz') && pageNumber !== null) {
            const sz = parseInt(urlParams.get('sz'), 10);
            let newStart = parseInt(pageNumber, 10) * sz;
            let parsedUrl = new URL(window.location.href);
            parsedUrl.searchParams.set('start', newStart);
            window.history.pushState({}, "", parsedUrl);
        }
    });
};

export default handleTileEvents;
