'use strict';

var processInclude = require('base/util');

document.addEventListener('DOMContentLoaded', () => {
    processInclude(require('./productTiles/productTileCustom'));
    processInclude(require('./productTiles/productTileSwatch'));
    processInclude(require('./productTiles/productTileSize'));
    processInclude(require('./productTiles/productTileNotifyMe'));
    processInclude(require('./productTiles/productTileEvents'));
    processInclude(require('./productTiles/productTileAddToCart'));
});
