// eslint-disable-next-line wrap-iife
const pageCache = {};

const cronSingletons = {};

/**
 * Cache factory that returns different types of cache storages based on provided options.
 * Supports 'page' (in-memory), 'localStorage', and 'sessionStorage' caches.
 *
 * @param {Object} [options={}] - Configuration options for cache.
 * @param {string} [options.type='page'] - The type of storage ('page', 'localStorage', 'sessionStorage').
 * @param {string} [options.keyPrefix=''] - Prefix to prepend to cache keys.
 * @param {number} [options.ttl=60] - Default Time-To-Live (TTL) in seconds for cached items.
 * @param {number} [options.cronInterval=30] - Interval (in seconds) for the cron job that cleans expired cache entries.
 * @param {number} [options.batchSize=100] - Number of keys processed in each cron job iteration.
 * @returns {Object} - Returns a cache storage object with `get`, `set`, `remove`, and `keys` methods.
 */
export default function factory(options = {}) {
    const type = options.type || 'page';
    const keyPrefix = options.keyPrefix || '';
    const defaultTTL = options.ttl || 60;
    const cronInterval = options.cronInterval || 30; // Default interval is 30 seconds
    const batchSize = options.batchSize || 100; // Number of keys processed per batch

    /**
     * Adds a prefix to a cache key.
     *
     * @param {string} key - The original key to be prefixed.
     * @returns {string} - Returns the key with the applied prefix.
     */
    function withPrefix(key) {
        return keyPrefix + key;
    }

    const storages = {
        page: {
            get(key) {
                const cacheItem = pageCache[withPrefix(key)];
                if (!cacheItem) return null;

                const now = new Date().getTime();
                if (now > cacheItem.expiry) {
                    delete pageCache[withPrefix(key)];
                    return null;
                }
                return cacheItem.data;
            },
            set(key, data, ttl = defaultTTL) {
                const now = new Date().getTime();
                pageCache[withPrefix(key)] = {
                    data,
                    expiry: now + ttl * 1000 // Convert TTL to milliseconds
                };
            },
            remove(key) {
                delete pageCache[withPrefix(key)];
            },
            keys() {
                return Object.keys(pageCache).filter(k => k.startsWith(keyPrefix));
            }
        },
        localStorage: {
            get(key) {
                const cacheItem = JSON.parse(localStorage.getItem(withPrefix(key)));
                if (!cacheItem) return null;

                const now = new Date().getTime();
                if (now > cacheItem.expiry) {
                    localStorage.removeItem(withPrefix(key));
                    return null;
                }
                return cacheItem.data;
            },
            set(key, data, ttl = defaultTTL) {
                const now = new Date().getTime();
                const cacheItem = {
                    data,
                    expiry: now + ttl * 1000 // Convert TTL to milliseconds
                };
                localStorage.setItem(withPrefix(key), JSON.stringify(cacheItem));
            },
            remove(key) {
                localStorage.removeItem(withPrefix(key));
            },
            keys() {
                return Object.keys(localStorage).filter(k => k.startsWith(keyPrefix));
            }
        },
        sessionStorage: {
            get(key) {
                const cacheItem = JSON.parse(sessionStorage.getItem(withPrefix(key)));
                if (!cacheItem) return null;

                const now = new Date().getTime();
                if (now > cacheItem.expiry) {
                    sessionStorage.removeItem(withPrefix(key));
                    return null;
                }
                return cacheItem.data;
            },
            set(key, data, ttl = defaultTTL) {
                const now = new Date().getTime();
                const cacheItem = {
                    data,
                    expiry: now + ttl * 1000 // Convert TTL to milliseconds
                };
                sessionStorage.setItem(withPrefix(key), JSON.stringify(cacheItem));
            },
            remove(key) {
                sessionStorage.removeItem(withPrefix(key));
            },
            keys() {
                return Object.keys(sessionStorage).filter(k => k.startsWith(keyPrefix));
            }
        }
    };

    const selectedStorage = storages[type];

    /**
     * Singleton function to start a cron job that cleans up expired cache items.
     * Ensures that only one cron job runs per cache type.
     *
     * @param {string} type - The type of cache ('page', 'localStorage', 'sessionStorage').
     * @param {Object} storage - The storage object for the specific cache type.
     * @param {number} cronInterval - The interval (in seconds) between cron job executions.
     * @param {number} batchSize - The number of cache keys processed per cron job iteration.
     * @param {string} keyPrefix - The prefix for cache keys.
     */
    function startCronSingleton(type, storage, cronInterval, batchSize, keyPrefix) {
        if (cronSingletons[type]) {
            // If a cron job is already running for this type, do nothing
            return;
        }

        // Mark the cron job as started for this cache type
        cronSingletons[type] = true;

        /**
         * Function that cleans expired cache keys in batches.
         * It processes `batchSize` keys per iteration to avoid performance issues.
         */
        function cleanExpiredKeys() {
            const keys = storage.keys();
            const now = new Date().getTime();
            let keysProcessed = 0;

            // Process a limited number of keys per cron cycle
            for (let i = 0; i < keys.length && keysProcessed < batchSize; i++) {
                const key = keys[i];
                const cacheItem = JSON.parse(localStorage.getItem(key));

                // Check if the cache item has expired and remove it if necessary
                if (cacheItem && now > cacheItem.expiry) {
                    storage.remove(key.replace(keyPrefix, ''));
                    keysProcessed++;
                }
            }

            // Schedule the next cron job execution
            setTimeout(cleanExpiredKeys, cronInterval * 1000);
        }

        // Start the cron job with the defined interval
        setTimeout(cleanExpiredKeys, cronInterval * 1000);
    }

    // Start the cron job for the selected cache type if not already started
    startCronSingleton(type, selectedStorage, cronInterval, batchSize, keyPrefix);

    return selectedStorage;
}
