import { queryFirst, addClass, removeClass } from '../domUtil.js';
import { HIDDEN_CLASS } from '../constants.js';
import { getSelectedPid } from './utils/productTileData.js';
const { SELECTOR_PRODUCT_TILE, SELECTOR_NOTIFY_ME_DIALOG_PID_INPUT, SELECTOR_NOTIFY_ME_DIALOG_CONTAINER, SELECTOR_NOTIFY_ME_DIALOG_FORM, SELECTOR_NOTIFY_ME_BTN } = require('./productTileConstants');

const $body = $('body');

const handleTileNotifyMe = () => {
    const customerEmail = (document.getElementById('notifyEmail') || {}).value;
    if (customerEmail) {
        queryFirst('.notify-email').value = customerEmail;
    }

    $body.on('click', SELECTOR_NOTIFY_ME_BTN, e => {
        e.preventDefault();
        const el = e.currentTarget;
        const container = el.closest(SELECTOR_PRODUCT_TILE);

        const pid = getSelectedPid(container);

        const pidInput = queryFirst(SELECTOR_NOTIFY_ME_DIALOG_PID_INPUT);
        pidInput.value = pid;

        const notifyContainer = queryFirst(SELECTOR_NOTIFY_ME_DIALOG_CONTAINER);
        const notifyForm = queryFirst(SELECTOR_NOTIFY_ME_DIALOG_FORM, notifyContainer);
        removeClass(notifyForm, HIDDEN_CLASS);
        const successMessage = queryFirst('.js-notify-confirm-msg', notifyContainer);
        successMessage.innerHTML = '';
        addClass(queryFirst('.js-notify-confirm-block', notifyContainer), HIDDEN_CLASS);
        addClass(queryFirst('.footer-close-link', notifyContainer), HIDDEN_CLASS);
    });
};

export default handleTileNotifyMe;
