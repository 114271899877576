import { queryAll, queryFirst, addClass, removeClass, hasClass, toggleClass } from '../domUtil';
import { getJSON } from '../util';
import { HIDDEN_CLASS, ONE_SIZE_ID, ONE_SIZE_LABEL } from '../constants';
import { getNestedValue } from '../util';
import { hideStrikeHearts, setWishlistProductId, showStrikeHearts } from '../wishlist/helper';
const { productTilePriceContent } = require('lilly/templates');
import {
    CLASS_SIZE_SELECTED,
    CLASS_SWATCH_SELECTED,
    SELECTOR_ADD_TO_CART_BTN,
    SELECTOR_EARLY_ACCESS_CTA_SIGN_IN,
    SELECTOR_EARLY_ACCESS_CTA_SIGN_UP,
    SELECTOR_NOTIFY_ME_BTN,
    CLASS_BADGE_HIDE,
    CLASS_BADGE_SHOW,
    SELECTOR_SOLDOUT_MSG,
    SELECTOR_TILE_PRICE_WRAPPER
} from './productTileConstants';
import { productTileSlide, productTileSlidePicture, noImageIcon } from '../templates';

const $body = $('body');
const SINGLE_IMAGE_CLASS = 'm-one-image';
/**
 *
 * @param {HTMLElement} elImgContainer images container
 */
function refreshCarousel(elImgContainer, count) {
    const swiperEl = elImgContainer.closest('.js-tile-carousel');
    const { swiper } = swiperEl;

    if (swiperEl) {
        if (hasClass(swiperEl, SINGLE_IMAGE_CLASS)) {
            // single image to swiper
            if (count > 1) {
                removeClass(swiperEl, SINGLE_IMAGE_CLASS);
            }
        } else {
            // swiper to single image
            if (count < 2) {
                addClass(swiperEl, SINGLE_IMAGE_CLASS);

                swiper.disable();
            }
        }

        // updating swiper
        if (count > 1) {
            if (swiper.params.loop) {
                swiper.loopCreate();
            }
            swiper.slideTo(0);
            swiper.updateProgress();
            swiper.updateSize();
            swiper.updateSlides();
            swiper.update();
            swiper.enable();
        }
    }
}

const handleSwatchClickUpdates = () => {
    $body.on('click', '.clickable-swatch, .non-color-attribute', function () {
        const el = this;
        const productTile = el.closest('.js-product-tile');
        const elImgContainer = queryFirst('.js-swiper-inner-wrapper', productTile);
        const addToCartCTA = queryFirst(SELECTOR_ADD_TO_CART_BTN, productTile);
        const notifyMeButton = queryFirst(SELECTOR_NOTIFY_ME_BTN, productTile);
        const elSwatchContainer = queryFirst('.color-swatches', productTile);
        const elLoyaltyProductBadgeContainer = queryFirst('.loyalty-product-tile-badge', productTile);
        const addToWishlistIconPlp = queryFirst('.wishlist-actions', productTile);
        const loyaltyEarlyAccessLockPlp = queryFirst('.loyalty-early-access-lock-container', productTile);
        const isLoyaltyHeaderProfile = getNestedValue(window, 'lillyUtils.profile') || {};
        const { isLoyaltyProgramMember, isLoyaltyAuthenticated, isLoyaltyEnabled } = isLoyaltyHeaderProfile;
        const simpleBadge = queryFirst('.js-badge', productTile);
        const elProductBadgeTxt = queryFirst('.product-tile-badge-text', simpleBadge);
        const earlyAccessBadge = queryFirst('.js-loyalty-badge', productTile);
        const elTileImgURL = queryFirst('.tile-img-link', productTile);
        const elProductName = queryFirst('.pdp-link .product-name a', productTile);
        const sizeContainer = queryFirst('div.quick-buy ul.size-container', productTile);
        const clubLillySignIn = queryFirst(SELECTOR_EARLY_ACCESS_CTA_SIGN_IN, productTile);
        // join
        const clubLillySignUp = queryFirst(SELECTOR_EARLY_ACCESS_CTA_SIGN_UP, productTile);

        const soldoutMsgEl = queryFirst(SELECTOR_SOLDOUT_MSG, productTile);
        const tilePriceWrapperEl = queryFirst(SELECTOR_TILE_PRICE_WRAPPER, productTile);

        if (addToCartCTA) {
            addToCartCTA.disabled = true;
        }
        if (notifyMeButton) {
            notifyMeButton.disabled = true;
        }

        const data = el.dataset;
        const url = data.swatchurl || data.attrUrl;

        const spinner = $(productTile).spinner();
        spinner.start();

        getJSON(
            url,
            'get',
            {},
            data => {
                // handle image update on swatch click
                const { category_id: uTagCid } = window.utag_data || {};
                const imageType = queryFirst('img, picture img', productTile)?.dataset.image || 'large';
                let imageData = data.product.images[imageType];
                const imageDataLength = imageData.length;
                let sliderTmp = '';
                $body.trigger('swatchClickEvent', el.closest('.product-tile'));

                if (hasClass(el, 'clickable-swatch')) {
                    removeClass(queryAll('.clickable-swatch', elSwatchContainer), CLASS_SWATCH_SELECTED);
                } else if (hasClass(el, 'non-color-attribute')) {
                    removeClass(queryAll('.non-color-attribute', elSwatchContainer), CLASS_SWATCH_SELECTED);
                } else if (hasClass(el, 'size-btn')) {
                    removeClass(queryAll('.size-btn', elSwatchContainer), CLASS_SIZE_SELECTED);
                }
                addClass(el, CLASS_SWATCH_SELECTED);

                const { merchAltImageParams } = data.product;
                if (merchAltImageParams) {
                    for (let i = 0, n = merchAltImageParams.length; i < n; i++) {
                        const [categoryId, swapIndex] = merchAltImageParams[i].split(',');
                        let adjustedSwapIndex = swapIndex - 1;
                        if (uTagCid === categoryId && adjustedSwapIndex >= 0 && adjustedSwapIndex < imageDataLength) {
                            // Add selected image to front of image queue, shift all others over
                            imageData = imageData.splice(adjustedSwapIndex, 1).concat(imageData);
                        }
                    }
                }

                removeClass(elImgContainer, 'tile-noimage');

                imageData.forEach((el, i) => {
                    if (Object.keys(el.sources).length !== 0) {
                        sliderTmp += productTileSlidePicture(el, i, imageType);
                    } else {
                        sliderTmp += productTileSlide(el, i);
                    }
                });

                if (sliderTmp === '') {
                    addClass(elImgContainer, 'tile-noimage');
                    elImgContainer.innerHTML = noImageIcon;
                } else {
                    elImgContainer.innerHTML = sliderTmp;
                }

                refreshCarousel(elImgContainer, imageData.length);

                // handle badging update on swatch click
                const { product, productBadge } = data;
                const { earlyAccess } = product;

                addClass(loyaltyEarlyAccessLockPlp, HIDDEN_CLASS);
                removeClass(addToWishlistIconPlp, HIDDEN_CLASS);
                addClass(elLoyaltyProductBadgeContainer, CLASS_BADGE_HIDE);

                // hide all badges
                removeClass([simpleBadge, earlyAccessBadge], CLASS_BADGE_SHOW);
                addClass([simpleBadge, earlyAccessBadge], CLASS_BADGE_HIDE);

                // productTile

                if (isLoyaltyEnabled && earlyAccess && earlyAccess.isEarlyAccessProduct) {
                    if (isLoyaltyAuthenticated) {
                        if (isLoyaltyProgramMember) {
                            // the “Add to tote +” is displayed for the logged-in loyalty user
                            addClass(clubLillySignUp, HIDDEN_CLASS);
                            addClass(clubLillySignIn, HIDDEN_CLASS);

                            removeClass(addToCartCTA, HIDDEN_CLASS);
                        } else {
                            // the 'Join Club Lilly>' is displayed for the logged-in non-loyalty user
                            removeClass(clubLillySignUp, HIDDEN_CLASS);
                            addClass(clubLillySignIn, HIDDEN_CLASS);

                            addClass(addToCartCTA, HIDDEN_CLASS);
                        }
                    } else {
                        // the 'Sign in to create an account>' is displayed for the guest user
                        addClass(clubLillySignUp, HIDDEN_CLASS);
                        removeClass(clubLillySignIn, HIDDEN_CLASS);

                        addClass(addToCartCTA, HIDDEN_CLASS);
                    }

                    addClass(addToWishlistIconPlp, HIDDEN_CLASS);
                    removeClass(loyaltyEarlyAccessLockPlp, HIDDEN_CLASS);
                    removeClass(elLoyaltyProductBadgeContainer, CLASS_BADGE_HIDE);
                    addClass(earlyAccessBadge, CLASS_BADGE_SHOW); // show early access badge
                } else if (simpleBadge && productBadge && productBadge.badgeText.length) {
                    addClass(simpleBadge, CLASS_BADGE_SHOW); // show simple badge

                    elProductBadgeTxt.style.color = productBadge.badgeTextColor || 'inherit';
                    elProductBadgeTxt.textContent = productBadge.badgeText;
                } else {
                    if (clubLillySignIn) {
                        addClass(clubLillySignIn, HIDDEN_CLASS);
                    }
                    if (clubLillySignUp) {
                        addClass(clubLillySignUp, HIDDEN_CLASS);
                    }

                    removeClass(addToWishlistIconPlp, HIDDEN_CLASS);
                    addClass(loyaltyEarlyAccessLockPlp, HIDDEN_CLASS);
                }

                // handle img link update on swatch click
                const { tileImageUrl, id, quickViewURL } = data.product;

                if (tileImageUrl && tileImageUrl.length) {
                    elTileImgURL.href = tileImageUrl;
                    elTileImgURL.dataset.hrefOrig = tileImageUrl;
                }

                const productTileProductWrapper = el.closest('.product');
                productTileProductWrapper.dataset.pid = id;
                productTileProductWrapper.dataset.wishlistId = id;
                setWishlistProductId(id, productTileProductWrapper);
                const wishlistButton = queryFirst('.add-to-wish-list', productTileProductWrapper);
                if (wishlistButton && hasClass(wishlistButton, 'added-to-wish-list')) {
                    removeClass(wishlistButton, 'added-to-wish-list');
                    const assistiveText = wishlistButton.getAttribute('data-assistive-text');
                    wishlistButton.setAttribute('aria-label', assistiveText);
                }

                const quickviewButton = queryFirst('.quickview', productTileProductWrapper);
                if (quickviewButton && quickViewURL) {
                    quickviewButton.dataset.href = quickViewURL;
                }

                const { productDetailsUrl, isNotifyMeEnabled } = data.product;
                const { variationAttributes = [] } = data.product;
                let li = '';

                let isSoldOut = false;

                let selectedColor = null;
                let selectedSize = null;
                let atLeastOneSizeAvailable = false;
                variationAttributes.forEach(variationAttr => {
                    if (variationAttr.attributeId === 'color') {
                        // eslint-disable-next-line consistent-return, array-callback-return
                        selectedColor = variationAttr.values.find(colorItem => {
                            if (colorItem.selected) {
                                return colorItem;
                            }
                        });
                    }
                    if (variationAttr.attributeId === 'size') {
                        const { values: sizes = [] } = variationAttr;
                        let selected = '';
                        const indexResult = sizes.findIndex(item => item.selected === true);
                        const selectedIndex = ~indexResult ? indexResult : -1;

                        sizeContainer.innerHTML = '';
                        const sizesLength = sizes.length;
                        if (sizesLength) {
                            const title = queryFirst('.quick-buy-notifyme .select-size-label')?.value.split(':') || [];
                            sizes.forEach(size => {
                                selected = sizes[selectedIndex]?.id === size.id;

                                if (size.selected) {
                                    selectedSize = size;
                                }

                                if (selected) {
                                    if (tileImageUrl.length) {
                                        elTileImgURL.href = tileImageUrl + '&dwvar_' + id + '_size=' + size.id;
                                    }
                                    if (productDetailsUrl.length) {
                                        elProductName.href = productDetailsUrl + '&dwvar_' + id + '_size=' + size.id;
                                    }
                                }

                                const isNAStock = !!(!size.inStock || size.forceOutOfStock || product.isDirectlyPurchasable === false || size.isRestricted);

                                if (!isNAStock) {
                                    atLeastOneSizeAvailable = true;
                                }
                                li += `
                                <li class="size-list ${size.id === ONE_SIZE_ID ? 'd-none' : ''}">
                                    <button title="${title[0]}: ${size.id}"
                                    class="btn size-btn ${sizesLength === 1 || size.selected ? 'selected' : ''} ${isNAStock ? 'not-available' : ''}"
                                    data-is-not-available="${isNAStock.toString()}"
                                    data-attr-url="${size.url}" data-attr-value="${size.id}"
                                    data-value-id="${size.id}"
                                    aria-label="${title[0]} ${size.id}"
                                    data-variation-id="${size.variantID}"
                                    data-stock-notification="${size.stockNotificationAllowed}">
                                    <span>${size.id === ONE_SIZE_ID ? ONE_SIZE_LABEL : size.id}</span>
                                    </button>
                                </li>`;
                            });
                            sizeContainer.innerHTML = li;

                            // only for one size we have preselected size,
                            // and in that case we need to update CTAs
                            if (sizesLength === 1) {
                                productTile.dispatchEvent(
                                    new CustomEvent('product:tile:cta:update', {
                                        bubbles: true,
                                        detail: {}
                                    })
                                );
                            }
                        }
                    }
                });

                const showAddToCart = atLeastOneSizeAvailable && !(
                    earlyAccess && earlyAccess.isEarlyAccessProduct && isLoyaltyEnabled
                    && (
                        !isLoyaltyProgramMember || !isLoyaltyAuthenticated
                    )
                );

                toggleClass(addToCartCTA, HIDDEN_CLASS, !showAddToCart);
                toggleClass(notifyMeButton, HIDDEN_CLASS, atLeastOneSizeAvailable || !isNotifyMeEnabled);
                toggleClass(soldoutMsgEl, HIDDEN_CLASS, atLeastOneSizeAvailable || isNotifyMeEnabled);

                if (data.productTile) {
                    const { html: priceMainHtml } = data.productTile.price;
                    const { html: priceHoverHtml } = data.productTile.priceHover;

                    tilePriceWrapperEl.innerHTML = productTilePriceContent(priceMainHtml, priceHoverHtml);
                }

                $body.trigger('swatchChangeEventCustom', { el, productData: data.product });
                spinner.stop();
            },
            () => {
                spinner.stop();
            }
        );
    });
};

export default handleSwatchClickUpdates;
