import { queryFirst } from '../domUtil';
const SELECTOR_DATA_ELEMENT = '.js-ajax-data';
let renewCSRFCronAssigned = false;

// 2700s = 45m
const renewCSRFCronInterval = 2700;

import cache from './cache';

const { get: getCustomerCache, set: setCustomerCache } = cache({
    keyPrefix: 'customer-',
    ttl: 30
});

const { get: getProductInventoryCache, set: setProductInventoryCache } = cache({
    keyPrefix: 'product-inventory-',
    ttl: 180
});

/**
 * Get account data element
 *
 * @returns {null|HTMLElement} account data el
 */
function getDataElement() {
    return queryFirst(SELECTOR_DATA_ELEMENT);
}

/**
 * Singleton function to start a cron job renew csrf token
 *
 * @param {number} cronInterval - The interval (in seconds) between cron job executions.
 */
function startCronSingleton(cronInterval) {
    if (renewCSRFCronAssigned) {
        return;
    }

    // Mark the cron job as started
    renewCSRFCronAssigned = true;

    /**
     * Function that cleans expired cache keys in batches.
     * It processes `batchSize` keys per iteration to avoid performance issues.
     */
    async function refreshTokenFunc() {
        const dataEl = getDataElement();

        const { csrfTokenName, csrfTokenValue, refreshToken } = dataEl.dataset;
        const targetUrl = `${refreshToken}?${csrfTokenName}=${csrfTokenValue}`;
        const response = await fetch(targetUrl);

        if (!response.ok) {
            return;
        }

        const { token } = await response.json();

        dataEl.dataset.csrfTokenValue = token;

        setTimeout(refreshTokenFunc, cronInterval * 1000);
    }

    // Start the cron job with the defined interval
    setTimeout(refreshTokenFunc, cronInterval * 1000);
}

/**
 * Get Customer Data by URL
 * @async
 * @private
 * @param {string} customerDataUrl data base url
 * @param {string} csrfTokenName csrf Toke Name
 * @param {string} csrfTokenValue csrf Toke Value
 * @returns {Promise<Object>} Customer data object
 */
async function getCustomerDataAjaxResult(customerDataUrl, csrfTokenName, csrfTokenValue) {
    let targetUrl = `${customerDataUrl}?${csrfTokenName}=${csrfTokenValue}`;

    const result = {
        isAuthenticated: false
    };

    const response = await fetch(targetUrl);

    if (!response.ok) {
        return result;
    }
    const { isAuthenticated, isLoyaltyMember } = await response.json();

    result.isAuthenticated = isAuthenticated;
    result.isLoyaltyMember = isLoyaltyMember;

    return result;
}

/**
 * Get Customer Data
 * @async
 * @param {string} customerDataUrl data base url
 * @param {string} csrfTokenName csrf Toke Name
 * @param {string} csrfTokenValue csrf Toke Value
 * @returns {Promise<Object>} Customer data object
 */
export async function getCustomerData() {
    const dataEl = getDataElement();

    const cacheKey = 'data';
    const { csrfTokenName, csrfTokenValue, customerDataUrl } = dataEl.dataset;

    const cachedResult = getCustomerCache(cacheKey);
    if (cachedResult) {
        return cachedResult;
    }

    const result = await getCustomerDataAjaxResult(customerDataUrl, csrfTokenName, csrfTokenValue);
    setCustomerCache(cacheKey, result);
    return result;
}

/**
 * Get Product inventory
 *
 * @param {string} masterPid master product ID
 * @returns {null|Object} product info
 */
export async function getProductInventoryData(masterPid) {
    let inventory = getProductInventoryCache(masterPid);

    if (inventory) {
        return inventory;
    }

    const dataEl = getDataElement();
    if (!dataEl) {
        return null;
    }

    const { csrfTokenName, csrfTokenValue, productInventoryUrl } = dataEl.dataset;

    const targetUrl = `${productInventoryUrl}?pid=${masterPid}&${csrfTokenName}=${csrfTokenValue}`;
    const response = await fetch(targetUrl);

    if (!response.ok) {
        return null;
    }

    const result = {};

    const { variants } = await response.json();

    result.variants = variants;

    setProductInventoryCache(masterPid, result);

    return result;
}

startCronSingleton(renewCSRFCronInterval);
