import { queryAll, queryFirst, addClass, removeClass, hasClass } from '../domUtil';
import { appendToUrl } from '../util';
import { NOT_AVAILABLE_STATUS as NOT_AVAILABLE, NOT_AVAILABLE_CLASS } from '../constants';

import {
    CLASS_SIZE_SELECTED,
    SELECTOR_PRODUCT_TILE,
    SELECTOR_PRODUCT_TILE_LINK,
    SELECTOR_TILE_SWATCH,
    SELECTOR_SIZE_LIST,
    SELECTOR_SIZE_BTN,
    SELECTOR_SIZE_BTN_IN_TILE,
    SELECTOR_SIZE_SELECTOR,
    SELECTOR_SIZE_SELECTED_ASSISTIVE_TEXT,
    SELECTOR_TILE_SWATCH_SELECTED
} from './productTileConstants';

import { getProductInventoryData } from '../utils/ajaxData';
import { getProductInfo } from './utils/productTileData';

const $body = $('body');

/**
 * Update Color Elements with selected size
 *
 * @param {HTMLElement} container tile container
 * @param {string} selectedSizeValue selected size value
 * @param {Object} [productInventory] Product inventory object
 */
async function updateColorElements(container, selectedSizeValue, productInventory) {
    const productInfoVariants = getProductInfo(container);
    const allColorElements = queryAll(SELECTOR_TILE_SWATCH, container);

    const { masterId } = container.dataset;

    productInventory = productInventory || await getProductInventoryData(masterId);

    allColorElements.forEach(colorElement => {
        let selectedColorValue = colorElement.dataset.attrValue;
        let product = productInfoVariants[selectedColorValue];

        if (colorElement && product.sizes?.[selectedSizeValue]) {
            const sizeID = product.sizes[selectedSizeValue].ID;

            if (productInventory.variants?.[sizeID]) {
                const status = productInventory.variants[sizeID].availabilityStatus;

                if (status === NOT_AVAILABLE) {
                    addClass(colorElement, NOT_AVAILABLE_CLASS);
                } else {
                    removeClass(colorElement, NOT_AVAILABLE_CLASS);
                }
            } else if (!hasClass(colorElement, NOT_AVAILABLE_CLASS)) {
                addClass(colorElement, NOT_AVAILABLE_CLASS);
            }
        } else if (!hasClass(colorElement, NOT_AVAILABLE_CLASS)) {
            addClass(colorElement, NOT_AVAILABLE_CLASS);
        }
    });
}

/**
 * handles size change
 *
 * @async
 * @param {HTMLElement} container - product container html element
 * @param {HTMLElement} sizeEl - product container html element
 * @param {string} selectedSizeValue - Selected size value
 */
async function onSizeChangeHandler(container, sizeEl, selectedSizeValue) {
    const selectedColorElement = queryFirst(SELECTOR_TILE_SWATCH_SELECTED, container);

    const { masterId } = container.dataset;
    const productInventory = await getProductInventoryData(masterId);

    updateColorElements(container, selectedSizeValue, productInventory);
    removeClass(queryAll(SELECTOR_SIZE_BTN, container), CLASS_SIZE_SELECTED);
    addClass(sizeEl, CLASS_SIZE_SELECTED);
    const sizeContainer = sizeEl.closest(SELECTOR_SIZE_SELECTOR);
    const assistiveElements = queryAll(SELECTOR_SIZE_SELECTED_ASSISTIVE_TEXT, sizeContainer);
    assistiveElements.forEach(eachElement => {
        const { outOfStock } = eachElement.dataset;
        if (eachElement.textContent.includes(outOfStock)) {
            eachElement.textContent = outOfStock;
        } else {
            eachElement.textContent = '';
        }
    });

    const assistiveElementOfSelected = queryFirst(SELECTOR_SIZE_SELECTED_ASSISTIVE_TEXT, sizeEl.closest(SELECTOR_SIZE_LIST));
    if (assistiveElementOfSelected) {
        const { selectedText, outOfStock } = assistiveElementOfSelected.dataset;
        assistiveElementOfSelected.textContent = selectedText;
        if (hasClass(sizeEl, NOT_AVAILABLE_CLASS)) {
            assistiveElementOfSelected.textContent += ' ' + outOfStock;
        }
    }

    const elProductLinks = queryAll(SELECTOR_PRODUCT_TILE_LINK, container);

    const productTileProductWrapper = container.closest('.product');
    productTileProductWrapper.dataset.pid = sizeEl.dataset.variationId;
    productTileProductWrapper.dataset.wishlistId = sizeEl.dataset.variationId;

    elProductLinks.forEach(el => {
        const { hrefOrig } = el.dataset;
        const targetUrl = appendToUrl(hrefOrig, {
            [`dwvar_${masterId}_size`]: selectedSizeValue
        });
        el.setAttribute('href', targetUrl);
    });

    container.dispatchEvent(
        new CustomEvent('product:tile:cta:update', {
            bubbles: true,
            detail: {
                productInventory,
                detail: {}
            }
        })
    );

    $body.trigger('product:tileSizeSelected', {
        selectedColorElement,
        selectedSizeElement: sizeEl,
        container
    });
}

/**
 * Initialize product tile element
 * @async
 * @param {HTMLElement} productTileEl product tile element
 */
async function initProductTile(productTileEl) {
    const sizeElements = queryAll(SELECTOR_SIZE_BTN, productTileEl);

    if (sizeElements.length === 1) {
        addClass(sizeElements, CLASS_SIZE_SELECTED);
        const selectedColorElement = queryFirst(SELECTOR_TILE_SWATCH_SELECTED, productTileEl);
        const el = sizeElements[0];
        $body.trigger('product:tileSizeSelected', {
            selectedColorElement,
            selectedSizeElement: el,
            container: productTileEl
        });
    }
}

const handleSizeClickUpdates = () => {
    // handle initialize event
    $body.on('product:tile:initialize', SELECTOR_PRODUCT_TILE, async e => {
        e.preventDefault();
        const productTileEl = e.currentTarget;
        const { productType, isProductTileSizeInitialized } = productTileEl.dataset;

        if (isProductTileSizeInitialized) {
            return;
        }

        productTileEl.dataset.isProductTileSizeInitialized = true;

        if (productType === 'set') {
            return;
        }

        await initProductTile(productTileEl);
    });

    $body.on('click', SELECTOR_SIZE_BTN_IN_TILE, async e => {
        e.preventDefault();

        const el = e.currentTarget;
        const productTile = el.closest(SELECTOR_PRODUCT_TILE);
        const selectedSizeValue = el.dataset.attrValue;

        await onSizeChangeHandler(productTile, el, selectedSizeValue);
    });
};

export default handleSizeClickUpdates;
